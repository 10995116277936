import React from "react";
import Slider from "react-slick";

import { useTranslation } from "react-i18next";
import imgCR from "../../assets/image/home-6/png/l6-cutomer-review-shape.png";
import imgRS from "../../assets/image/home-6/png/l6-review-star.png";
import imgU3 from "../../assets/image/home-6/png/user-img-3.png";

const Testimonial = ({ className, ...rest }) => {
  const { t, i18n } = useTranslation();
  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerPadding: "20%",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 769,
        settings: {
          centerPadding: "0",
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className={className} {...rest}>
        <div className="l6-shape-2 absolute-top-right mt-28 mr-n15">
          <img src={imgCR} alt="" />
        </div>
        <div className="container">
          {/* Section Title */}
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10">
              <div className="text-center mb-13 mb-lg-21">
                <h2 className="font-size-11 mb-7 text-light-digi">
                  {t("index_testimonial_title")}
                  </h2>
                <p className="font-size-7 mb-0 px-xl-10">
                {t("index_testimonial_paragraph1")}
                </p>
              </div>
            </div>
          </div>
          {/* End Section Title */}
          <div className="row">
            <div className="col-12">
              <Slider
                {...slickSettings}
                css={`
                  .slick-slide {
                    margin: 0 1rem;
                  }
                  .slick-dots {
                    display: flex !important;
                  }
                `}
                className="l6-testimonial"
              >
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white border border-default-color-5 rounded-10 pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <div className="mb-10">
                    <img src={imgRS} alt="" />
                  </div>
                  <p className="font-size-8 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                  <i>{t("testimonial_person1_paragraph1")}</i>
                    </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={imgU3} alt="" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-7 text-dark-cloud mb-0">
                      {t("testimonial_person1_name")}
                      </h5>
                      <p className="font-size-5 text-stone mb-0">
                      {t("testimonial_person1_job")}
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}
                {/* Single Testimonial */}
                <div className="single-testimoial bg-white border border-default-color-5 rounded-10 pt-5 pb-4 pt-sm-11 pt-md-11 pb-sm-10 pb-md-10 pl-5 pl-sm-11 pl-md-11 pr-6 pr-md-12 mx-md-0 focus-reset">
                  <div className="mb-10">
                    <img src={imgRS} alt="" />
                  </div>
                  <p className="font-size-8 mb-13 pr-sm-5 pr-md-0 pr-xl-4 text-dark-cloud">
                  <i> {t("testimonial_person2_paragraph1")}</i>
                  </p>
                  <div className="d-flex align-items-center">
                    <div className="mr-6">
                      <img src={imgU3} alt="" />
                    </div>
                    <div className="info">
                      <h5 className="font-size-7 text-dark-cloud mb-0">
                      {t("testimonial_person2_name")}
                      </h5>
                      <p className="font-size-5 text-stone mb-0">
                      {t("testimonial_person2_job")}
                      </p>
                    </div>
                  </div>
                </div>
                {/* End Single Testimonial */}
               </Slider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
